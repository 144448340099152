.sidebar-filler {
	min-width: 224px;
	@media screen and (max-width: 636px) {
		display: none;
	}

}
.sidebar {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 224px;
	height: 100%;
	background-color: $darkpurple;
	color: white;
	overflow: scroll;

	.logo {
		margin-top: 50px;
		width: 80%;
		img {
			width: 100%;
			height: auto;
		}
	}
	.bottom-nav {
		width: 100%;
		// height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		ul {
			list-style: none;
			padding-left: 0;
			li {
				padding: 10px 0;
				text-align: center;
				border: 1px solid $lightpurple;
				border-left: none;
				border-right: none;
				transition: all 200ms ease-in-out;
				&.current {
					background: black;
				}

				&:hover {
					background: #361057;
					border: 1px solid $darkpurple;
				}
			}

			a {
				color: white;
				text-decoration: none;
			}
		}
	}
	@media screen and (max-width: 636px) {
		display: none;
	}
}

.sidebar-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    .user--image {
        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 5%;
        }
    }
    .user--info {
        margin-top: 15px;
        text-align: center;
        .name {
            font-weight: bold;
        }
    }
}

.links {
	width: 100%;
	height: 51%;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	a {
		color: white;
		text-decoration: none;
		padding: 10px 0;
		text-align: center;
		border-top: 1px solid $lightpurple;
		border-left: none;
		border-right: none;
		transition: all 200ms ease-in-out;
		&:hover {
			background: #361057;
			border-top: 1px solid $darkpurple;
		}
	}
	a:last-of-type {
		border-bottom: 1px solid $lightpurple;
		&:hover {
			border-bottom: 1px solid $darkpurple;
		}
	}
	.current {
		background: #361057;
	}
	.nav-link {
		color: white;
		text-decoration: none;
		padding: 10px 0;
		text-align: center;
		border-top: 1px solid $lightpurple;
		border-left: none;
		border-right: none;
		transition: all 200ms ease-in-out;
		cursor: pointer;
		&:hover {
			background: #361057;
			border-top: 1px solid $darkpurple;
		}
	}
	// .nav-link:last-of-type {
	// 	border-bottom: 1px solid $lightpurple;
	// 	&:hover {
	// 		border-bottom: 1px solid $darkpurple;
	// 	}
	// }
	.selected {
		background: #361057;
	}
}

.dropdown-wrap{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    min-height: 50px;
    background-color: $darkpurple;
    color: white;
    @media screen and (min-width: 636px) {
      display: none;
    }
    .menu {
      margin: 0 20px;
      order: 1;
    }
    .title {
      display: flex;
      justify-content: center;
    }
}

.dropdown {
    position: absolute;
    top: 50px;
    left: 0;

    display: flex;
	flex-direction: column;
	align-items: center;
    color: white;
    background: $darkpurple;
    height: 0;
    width: 100vw;
    z-index: 2;
    overflow: hidden;
  
    transition: height 0.4s ease-in-out;
  
    &.active {
      height: 80vh;
    }
}
