.login {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: $backgroundColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    &__container {
        background-color: #EEEEEE;
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        min-width: 240px;
        min-height: 400px;
        height: 45%;
        margin: 15px;
        border-radius: 10px;
        color: black;
        text-align: center;

        .login__image{
            width: 45%;
            height: 100%;
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px 0 0 10px;
            }
        }

        .login__form {
            width: 55%;
            min-width: 240px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h1{
                font-size: 1rem;
                font-weight: 400;
                color: $purple;
            }
            label{
                visibility: hidden;
            }
            .login__container__items{
                border: 1px solid $gold;
                border-radius: 5px;
                width: 80%;
                background-color: white;
                height: 38px;
                display: flex;
                align-items: center;
                margin: 10px;
                padding-right: 15px;
                img{
                    margin: 0 15px;
                    transition: all 300ms ease-in-out;
                    &:hover{
                        transform: scale(1.2);
                        cursor: pointer;
                    }
                }
                input{
                    border: none;
                    background-color: white;
                    color: $purple;
                    transition: all 300ms ease-in-out;
                    width: 100%;
                    font-family: 'Gill Sans';
                }
                input:focus{
                    outline: none;
                    border: none;
                    border-bottom: 1px solid rgb(168, 168, 168);
                    font-weight: 600;
                    color: $purple;
                }
                input:-webkit-autofill{
                    -webkit-text-fill-color: $purple !important;
                    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
                }
            }
            #submit-button {
                justify-content: center;
                background-color: $gold;
                padding: 5px 40px 5px;
                color: white;
                border: none;
                cursor: pointer;
                transition: all 300ms ease-in-out;
                &:hover {
                    background-color: $lightgold;
                    width: 82%;
                    color: $purple;
                    font-weight: 600;
                }
            }
        }

        &__forgotText {
            margin-top: 35px;


            &__forgotLinks {
                margin-top: 25px;
                text-decoration: none;
                color: $purple;
                font-size: 0.8rem;
            }
        }
    }
}