.popup-overlay{
    background-color: #00000065;
}

.popup-content{
    background-color: white;
    border: 1px solid $purple;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    position: relative;
    transform-origin: center;
    animation: popIn 300ms ease-in-out forwards;
    .close{
        position: absolute;
        top: 0;
        right: 0;
        margin: 2px;
        padding: 2px;
        padding-bottom: 3px;
        width: 20px;
        height: 21px;
        border: none;
        border-radius: 3px;
        background-color: white;
        color: grey;
        font-weight: 600;
        font-size: 1rem;
        transition: all 300ms ease-in-out;
            &:hover{
                cursor: pointer;
                background-color: rgb(211, 79, 79);
                color: white;
            }
    }
    .close:focus{
        outline: 0;
    }
    .signatureCanvas {
        border: 2px solid black;
        border-radius: 3px;
        min-width: 325px;
        width: 50vw;
        max-width: 615px;
        height: 200px;
        cursor: crosshair;
        margin-right: 1px;
        z-index: 1000;
    }
    .signature-buttons{
        margin-top: 1rem;
        button{
            border: none;
            background-color: $purple;
            color: white;
            margin: 0 25px;
            padding: 0.5rem 1rem;
            transition: all 300ms ease-in-out;
            &:hover{
                cursor: pointer;
                background-color: $purplehover;
            }
        }
    }
    .modal{
        display: flex;
        flex-direction: column;
        .delete-buttons{
            margin-top: 1rem;
            display: flex;
            justify-content: center;
        }
    }
}