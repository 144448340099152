@font-face {
    font-family: 'Gill Sans';
    font-style: normal;
    font-weight: normal;
    src: local('Gill Sans Std Light'),
        url('../../assets/fonts/GillSansStdLight.woff') format('woff');
    font-display: swap;
}

html, body, #root {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Gill Sans", -apple-system, BlinkMacSystemFont, "", "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

* {
    box-sizing: border-box;
}

a{
    text-decoration: none;
}

textarea, input, select{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 0.9rem;
    border-radius: 3px;
    border-width: 1px;
}