.proposals{
    width: 100%;
    height: 100%;
    position: relative;
    .error{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.proposals-buttons{
    margin: 0 auto;
    width: 90%;
    padding: 1rem;
    display: flex;
    .search-form{
        display: flex;
        align-items: center;
        width: 100%; 
        @media screen and (max-width: 950px) {
            display: block;
            button {
                margin-left: 0px;
            }

            .row {
                margin-top: 10px;
            }
        }

        input[type="radio"] {
            margin-left: 20px;
        }
        .search-box{
            height: 30px;
            width: 50%;
            min-width: 120px;
        }

        .row {
            display: flex;
            align-items: center;
        }
        .search-buttons{
            display: flex;
            align-items: center;
            width: 50%;
            input{
                margin-left: 10px;
            }
            label{
                margin-top: 5px;
                margin-right: 10px;
                // min-width: 90px;
            }
        }
    }
    .create-new{
        background-color: $gold;
        &:hover{
            background-color: $purplehover;
        }
    }
}

.proposals-list{
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 90%;
    .proposal-card{
        // border: 1px solid gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 1rem;
        width: 170px;
        height: 200px;
        position: relative;
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
        transition: all 300ms ease-in-out;
        &:hover{
            transform: scale(1.05);
            cursor: pointer;
        }
        .dots{
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover{
                cursor: pointer;
            }
        }
        .menu{
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 10px;
            right: 20px;
            width: 75px;
            padding: 2px;
            // height: 50px;
            background-color: white;
            border: 1px solid $purple;
            border-radius: 3px;
            transition: all 300ms ease-in-out;
            transform-origin: top right;
            animation: growDown 300ms ease-in-out backwards;
            z-index: 1000;
            .menu-item{
                border: 1px solid lightgrey;
                margin: 2px;
                transition: all 300ms ease-in-out;
                &:hover{
                    background-color: $lightgold;
                    cursor: pointer;
                }
            }
        }
        .icon{
            width: 74px;
            height: auto;
            position: relative;
            .signed-notification{
                position: absolute;
                top: 15px;
                left: 15px;
                // background-color: rgba($color: #ffffff, $alpha: 1.0);
                color: green;
                img{
                    width: 50px;
                    height: auto;
                }
                p{
                    margin: 0;
                    margin-left: -5px;
                }
            }
        }
        .tags{
            margin-top: 12px;
            b{
                color: $purple;
            }
        }
    }
    @for $num from 1 through 25{
        .list-#{$num}{
            transform-origin: top;
            animation: fadeIn ($num * 100ms) ease-in-out forwards;
        }
    }
}