.proposal-main{
    width: 100vw;
    display: flex;
}

.no-id{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: rgb(9,7,37);
    background: linear-gradient(315deg, rgb(46, 39, 56) 0%, rgb(136, 122, 149) 48%, rgb(88, 82, 92) 88%);
    color: white;
}

.proposal-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: rgb(9,7,37);
    background: linear-gradient(315deg, rgb(46, 39, 56) 0%, rgb(136, 122, 149) 48%, rgb(88, 82, 92) 88%);
    scroll-behavior: smooth;
    scroll-padding-top: 0.5rem;
    .page{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        //max-height: 900px;
        //height: 96vh;
        min-height: 900px;
        padding-bottom: 50px;
        //max-height: 1200px;
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
        margin: 20px;
        background: $backgroundGradient;
        // overflow-y: auto;
        p{
            margin: 0.5rem 0;
        }

        @media print {
            box-shadow: none;
            margin: 0;
            min-width: 100vw !important;
            min-height: 100vh !important;
            max-width: 100vw !important;
            max-height: 100vh !important;
            
        }
    }
    .page:first-of-type{
        margin-top: 0.5rem;
        @media print {
            margin-top: 0rem;
        }
    }
    .page:last-of-type{
        margin-bottom: 1rem;
        @media print {
            margin-bottom: 0rem;
        }
    }
    .page-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        
        width: 100%;
        min-width: 320px;

        @media print {
            position: relative;
            break-inside: auto;
        }

        .header-image{
            margin-bottom: -0.3rem;
            img{
                width: 100%;
                height: auto;
            }
        }
        .header-box{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            padding: 2rem;
            text-align: center; 
            h1{
                text-transform: uppercase;
                margin: 0;
                margin-bottom: 16px;
                color: $orange;
            }
            h2, h3{
                margin: 0;
            }
        }
        .box{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            //height: 100%;
            margin: 0 auto;
            padding: 2rem 2rem 0rem 2rem;
            @media print {
                break-inside: auto;
                position: relative;
            }
            h3{
                text-transform: uppercase;
                margin: 0;
                color: $gold;
            }
        }
        .item{
            b{
                text-transform: uppercase;
            }
        }
        .center{
            text-align: center;
        }
        .no-padding{
            margin: 0;
            padding: 0;
            height: 10%;
        }
        .uppercase{
            text-transform: uppercase;
        }
        .gold-titles{
            h1, h2, h3{
                color: $orange;
                text-transform: uppercase;
                margin: 0;
            }
        }
        .small-font{
            font-size: 0.8rem;;
        }
        .purple{
            color: $purple;
        }
        .page-footer{
            position: absolute;
            bottom: 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 1rem;
            width: 100%;
            //height: 100%;
            margin-top: auto;
            text-align: center;
            .line{
                width: 90%;
                height: 1px;
                border-top: 1px solid $orange;
                margin: 0 auto;
            }
            p{
                color: $purple;
            }

            @media print {
                break-inside: avoid;
                position: relative;
                padding-bottom: 0rem;
            }
        }
        .dark-box{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            width: 100%;
            //height: 100%;
            max-height: 150px;
            margin: 0 auto;
            background-color: $darkpurple;

            @media print {
                break-inside: avoid;
                position: relative;
                background-color: $darkpurple;
            }

            h1, h2{
                margin: 0.8rem;
                text-align: center;
            }
            p{
                margin: 0.5rem;
                text-align: center;
                font-size: 0.9rem;
            }
        }
        .white-box{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            //height: 100%;
            margin: 0 auto;
            padding: 1rem 2rem 0rem 2rem;

            @media print {
                break-inside: auto;
                position: relative;
            }

            h3{
                text-transform: uppercase;
                margin: 0;
                color: $gold;
            }
        }
        .light-box{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            //height: 100%;
            margin: 0 auto;
            padding: 2rem;
            background-color: rgba(87, 12, 131, 0.055);
            h3{
                text-transform: uppercase;
                margin: 0;
                color: $gold;
            }
        }
        .table{
            display: flex;
            flex-direction: column;
            border: 1px solid black;
            .table-header{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                background-color: $darkpurple;
                height: 2.8rem;
                h3{
                    color: white;
                }
            }
            .table-row{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                border-top: 1px solid black;
                h4{
                    font-weight: 400;
                }
            }
            .table-row:nth-child(odd){
                background-color: $lightpurplebox;
            }
            .total{
                h4{
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
            .left-column{
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid black;
                width: 50%;
                text-align: center;
                height: 2.2rem;
            }
            .right-column{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50%;
                text-align: center;
                height: 2.2rem;
            }
        }
        .signature-box{
            // display: flex;
            flex-direction: column;
            align-items: center;
            width: 90%;
            border: 10px solid $lightpurplebox;
            background-color: white;
            margin-bottom: 20px;
            h3{
                padding: 0 20px;
                text-align: center;
            }
            .name-signature{
                display: flex;
                justify-content: center;
                input:-webkit-autofill{
                    -webkit-text-fill-color: $purple !important;
                    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
                    box-shadow: 0 0 0px 1000px #ffffff inset;
                }
                @media screen and (max-width: 736px) {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                }
                .input-box{
                    margin: 15px;
                    .input-label{
                        padding: 5px 10px;
                    }
                }
                .name, .signature{
                    height: 50px;
                    border: 2px solid $purple;
                    width: 225px;
                    display: flex;
                    // justify-content: center;
                    padding: 10px;
                    font-size: 0.8rem;
                    color: black;
                    align-items: center;
                    transition: all 300ms ease-in-out;
                    &:hover{
                        transform: scale(1.05);
                    }
                }
                .signature{
                    font-size: 0.9rem;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            .submit-approval{
                padding: 0.5rem 1rem;
                border: none;
                background-color: $purple;
                color: white;
                margin: 1rem;
                transition: all 300ms ease-in-out;
                &:hover{
                    cursor: pointer;
                    background-color: $purplehover;
                }
            }
            .submit-approval:disabled{
                background-color: $lightpurplebox;
                color: lightgray;
                &:hover{
                    cursor: not-allowed;
                    transform: none;
                }
            }
            .error{
                text-align: center;
            }
        }
        .signed{
            .name-signature{
                align-items: center;
                .input-box{
                    .input-signed{
                        border-bottom: 1px solid black;
                        width: 225px;
                        height: 45px;
                        display: flex;
                        // justify-content: center;
                        align-items: flex-end;
                    }
                    .signature{
                        border: none;
                    }
                }
            }
            .signed-date{
                text-align: center;
            }
        }
    }
    @media screen and (max-width: 950px) {
        #assumption-item{
            font-size: 0.8rem;
        }
    }
    @media screen and (max-width: 836px) {
        p, li, h4{
            font-size: 0.8rem;
        }
    }
    @media screen and (max-width: 636px) {
        h1 {
            font-size: 1.5rem;
        }

        h2 {
            font-size: 1.25rem;
        }

        .page {
            padding-bottom: 70px;
        }

        p, li, h4, #assumption-item{
            font-size: 0.7rem;
        }
    }
    @media screen and (max-width: 407px) {

        #assumption-item{
            font-size: 0.5rem;
        }
    }
    @media screen and (max-width: 340px) {
       h4{
            font-size: 0.6rem;
        }
    }

    @media print {
        overflow: visible;
        -webkit-print-color-adjust: exact; 
        background: white;

    }
}

@media print {
    .proposal-main .sidebar, .sidebar-filler {
        display: none;
    }
    @page {
        size: A4; /* DIN A4 standard, Europe */
        margin:0;
    }
    html, body {
        width: 210mm; // A4 Paper width
        height: 100%;
    }
}

