.main{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $backgroundColor;
    a{
        color: white;
    }
}

.main-window{
    width: 100vw;
    height: 100%;
    display: flex;
}

.main-container{
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: $backgroundColor;
    background: $backgroundGradient;
    a{
        color: white;
    }
    @media screen and (max-width: 636px) {
        flex-direction: column;
        justify-content: flex-start;
    }
}

.loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.loading{
    margin: 0 auto;
    margin: 10px;
    border: 3px solid $lightgold;
    border-top: 3px dotted $purple;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    animation: spin 1s linear infinite;
}
.error{
    transition: all 300ms ease-in-out;
    margin-top: -8px;
    margin-bottom: -7px;
    color: red;
    font-size: 0.8rem;
}