button {
	border: none;
	background-color: $purple;
	color: white;
	margin: 0 25px;
	padding: 0.5rem 1rem;
	transition: all 300ms ease-in-out;
	border-radius: 3px;
	&:hover {
		cursor: pointer;
		background-color: $purplehover;
	}
}

.darkred {
	background-color: darkred;
	&:hover {
		background-color: rgb(211, 90, 90);
	}
}

.darkgreen {
	background-color: darkgreen;
	&:hover {
		background-color: rgb(34, 158, 34);
	}
}

.gold{
	background-color: $gold;
	&:hover {
		background-color: $lightgold;
	}
}

.gray{
	background-color: #aaaaaa;
	&:hover {
		background-color: #777777;
	}
}

.red {
	background-color: #cc3333;
	&:hover {
		background-color: #990000;
	}
}