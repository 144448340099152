$backgroundColor: #3b3054;
$backgroundGradient: linear-gradient(148deg, rgba(255,255,255,1) 0%, rgb(211, 211, 211) 100%);
$orange: #e89624;
$gold: #FEBD11;
$lightgold: #fdd052;
$purple: #663293;
$purplehover: #9645dd;
$lightpurple: #592a83;
$darkpurple: #3b3054;
$lightpurplebox:  rgba(87, 12, 131, 0.055);

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes popIn {
    0% {
        transform: scale(0);
        // opacity: 0;
    }
    80% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}