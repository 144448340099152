.overview{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $backgroundGradient;
    // overflow: scroll;
    .welcome-message{
        width: 50%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1{
            color: $purple;
            font-weight: 400;
        }
    }
}

.coming-soon{
    color: $purple;
    font-weight: 400;
}