.ql-color .ql-picker-options [data-value=custom-color]:before {
    content: 'Pick Color';
  }
  
  .ql-color .ql-picker-options [data-value=custom-color] {
    background: none !important;
    width: 100% !important;
    height: 25px !important;
    text-align: center;
    color: blue;
    text-decoration: underline;
}

#blog-manager {
    display: grid;
    grid-template-columns: 300px 300px 300px;
    margin-top: 20px;
}

.blog{
    width: 100%;
    height: 100%;
    .blog-form{
        width: 70vw;
        margin: 20px auto;
        background-color: white;
        border-radius: 10px;
        padding: 20px 20px 20px 20px;

        a { color: black; }
        form{
            display: flex;
            flex-direction: column;
            .half-width{
                display: flex;
                justify-content: space-between;
                #date{
                    width: 200px;
                    display: block;
                }
                #alt_tag {
                    width: 200px;
                    display: block;
                }
                #img_input {
                    width: 200px;
                    display: block;
                }
            }
            input{
                border-radius: 2px;
                border: 1px solid grey;
                height: 30px;
                width: 100%;
                margin-bottom: 10px;
                padding-left: 20px;
                padding-top: 5px;
            }
            textarea{
                border-radius: 2px;
                height: 20vh;
                width: 100%;
                margin-bottom: 10px;
            }
            .editor{
                margin-bottom: 10px;
            }
        }
    }

    .blog-posts{
        margin: 20px;
        .blog-post{
            margin-bottom: 50px;
            max-width: 75vw;
            img{
                margin-bottom: -20px;
                width: 100%;
            }

        }
    }
}

@media screen and (max-width: 1100px) {
    #blog-manager {
        grid-template-columns: 300px 300px;
    }
}



@media screen and (max-width: 900px) {
    .checkbox {
        font-size: 14px;
    }
    .checkbox input{
        width: 20px !important;
    }

}

@media screen and (max-width: 800px) {
    #blog-manager {
        grid-template-columns: 300px;
    }
}


@media screen and (max-width: 600px) {
    .checkbox {
        font-size: 12px;
    }
    .checkbox input{
        width: 15px !important;
    }
}