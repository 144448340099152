.proposal-editor{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    background: rgb(9,7,37);
    background: linear-gradient(315deg, rgb(46, 39, 56) 0%, rgb(136, 122, 149) 48%, rgb(88, 82, 92) 88%);
    .toolbar{
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        justify-content: center;
        align-items: center;
        background: $backgroundGradient;
        height: 100px;
        z-index: 1;
        .buttons{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .signed{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .modal{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 320px;
            height: 100px;
            border-radius: 5px;
            background-color: white;
            padding: 20px;
        }
    }
    .editor{
        display: flex;
        flex-direction: column;
        width: 100%;

        padding: 1rem;
        // margin-top: 200px;
        .editor-block{
            // display: flex; //disable for safari
            align-items: center;
            // border: 1px solid lightgrey;
            margin: 20px 0;
            // padding: 1rem;
            background: $backgroundGradient;
            box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
            .editor-header{
                width: 100%;
                background-color: $darkpurple;
                color: white;
                text-align: center;
                h2{
                    margin: 0.5rem;
                    margin-top: 0;
                    padding: 0.5rem;
                }
            }
            h3{
                text-transform: uppercase;
                color: $gold;
                margin-top: 10px;
                margin-bottom: 7px;
                padding: 0 1rem;
            }
            p{
                padding: 0 1rem;
                margin-top: 5px;
                margin-bottom: 10px;
                font-size: 0.9rem;
            }
            .block{
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 0 1rem;
                margin-bottom: 1rem;
                .editor-map{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    // margin: 10px 0;
                    // border: 1px solid red;
                    .label-container{
                        width: 100%;
                        // border: 1px solid red;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .remove-button{
                            padding: 0.1rem 0.5rem;
                            font-size: 0.5rem;
                            margin: 0;
                            margin-bottom: -10px;
                        }
                    }
                    .costs-inputs{
                        display: flex;
                        margin-bottom: 1rem;
                        .inputs-container{
                            height: 30px;
                        }
                        label{
                            height: 30px;
                        }
                        input{
                            width: 75px;
                            margin: 0 5px;
                        }
                        input:disabled{
                            background-color: white;
                            font-weight: 600;
                            color: $darkpurple
                        }
                    }
                }
                .add-button{
                    max-width: 200px;
                    max-height: 30px;
                }
                .phase{
                    margin-top: 20px;
                }
                .total-container{
                    display: flex;
                    align-items: center;
                    button{
                        width: 200px;
                    }
                }
            }
            .editor-input-label{
                margin: 5px;
            }
            .editor-input{
                height: 30px;
                margin-bottom: 10px;
                padding: 5px;
                animation: fadeIn 500ms ease-in-out forwards;
            }
            .input-paragraph{
                width: 100%;
                height: 100px;
            }
            .input-description{
                height: 60px;
            }
            .names-container{
                width: 100%;
                display: flex;
                justify-content: center;
            }
            .half-width{
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 1rem;
                input{
                    max-width: 400px;
                }
            }
        }
        .column{
            flex-direction: column;
            align-items: flex-start;
        }
    }
}